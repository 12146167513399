<template>
    <div class="spare-parts">
        <div class="addSpare-info">
            <div class="addSpare-title">备件库退货地址</div>
        </div>
        <el-form :model="addSparePartsForm" ref="addSparePartsForm" label-position="right" :rules="addressRules" label-width="112px" @close="resetForm()">
            <el-form-item label="联系人"  prop="ship_name">
                <el-input v-model="addSparePartsForm.ship_name"  placeholder="请输入联系人" maxlength="20" show-word-limit style="width:520px"></el-input>
            </el-form-item>
            <el-form-item label="联系电话"  prop="phone">
                <el-input v-model="addSparePartsForm.phone" placeholder="请输入联系电话" maxlength="13" show-word-limit clearable style="width:520px"></el-input>
            </el-form-item>
            <el-form-item label="邮编" class="emil-num" prop="postcode">
                <el-input v-model.number="addSparePartsForm.postcode" placeholder="请输入邮编" maxlength="6" show-word-limit style="width:520px"></el-input>
                <!--                    <el-input-number v-model="addSparePartsForm.emil"  :max="999999" :controls="false"  placeholder="请输入邮编" style="width:340px"></el-input-number>-->
            </el-form-item>
            <el-form-item label="退货地址" class="where-address">
                <div style="display: flex;">
                    <el-select v-model="addSparePartsForm.province"  clearable placeholder="请选择省" style="width:166px;margin-right:10px;" @change="selectProvince">
                        <el-option
                                v-for="item in provinceList"
                                :key="item.area_id"
                                :label="item.area_name"
                                :value="item.area_name">
                        </el-option>
                    </el-select>
                    <el-select v-model="addSparePartsForm.city"  clearable placeholder="请选择市" style="width:166px;margin-right:10px;" @change="selectCity">
                        <el-option
                                v-for="item in cityList"
                                :key="item.area_id"
                                :label="item.area_name"
                                :value="item.area_name">
                        </el-option>
                    </el-select>
                    <el-select v-model="addSparePartsForm.area"  clearable placeholder="请选择区" style="width:166px;margin-right:10px;" @change="slelctArea">
                        <el-option
                                v-for="item in areaList"
                                :key="item.area_id"
                                :label="item.area_name"
                                :value="item.area_name">
                        </el-option>
                    </el-select>
                </div>
            </el-form-item>
            <el-form-item label="退货详细地址" prop="detailed_address">
                <el-input v-model="addSparePartsForm.detailed_address"  autocomplete="off" style="width:520px"
                          placeholder="请输入详细地址" maxlength="100" show-word-limit ></el-input>
            </el-form-item>
        </el-form>
        <div class="dialog-footer">
            <el-button style="margin-left: 30px" v-if="this.$route.query.detail==='detail'"  @click="resetBtn">返回</el-button>
            <el-button style="margin-left: 30px" v-if="this.$route.query.detail==='detail'" type="primary" @click="addressBtn('addSparePartsForm')">确定</el-button>
            <el-button type="primary" v-else  @click="addressBtn('addSparePartsForm')">保存</el-button>
        </div>
    </div>
</template>

<script>
    import {transactionGetArea,tranDistributionStuSalesAddress,tranDistributionStuSalesAddressList} from '@/utils/apis'
    export default {
        name: "StudentSpareParts",
        data(){
            //手机号验证
            let checkPhone = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error("手机号不能为空"));
                } else {
                    if (value.length === 11) {
                        // 11位手机号码
                        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
                        if (reg.test(value)) {
                            callback();
                        } else {
                            return callback(new Error("请输入正确的手机号"));
                        }
                    } else {
                        if (!/^0\d{2,3}-?\d{7,8}$/.test(value)) {
                            return callback(new Error("请输入正确的手机号"));
                        } else {
                            callback();
                        }
                    }
                }
            };
            //验证邮编
            let checkPostcode = (rule, value, callback) => {
                if (value) {
                    const reg = /[1-9]\d{5}(?!\d)/
                    if (reg.test(value)) {
                        callback();
                    } else {
                        return callback(new Error('邮编格式不正确'));
                    }
                } else {
                    callback();
                }  
            };
            return{
                addSparePartsForm:{
                    where_address:'',
                    province:'',
                    city:'',
                    area:'',
                    detailed_address:'',
                    postcode:'',
                    phone:'',
                    ship_name:'',
                },
                provinceList:[],
                cityList:[],//市
                areaList:[],//区
                addressRules: {
                    // where_address:[
                    //     {required: true, message: '请选择所在地区', trigger: ['blur', 'change']},
                    // ],
                    detailed_address:[
                        {required: true, message: '请输入详细地址', trigger: 'blur'},
                        { min: 2, max: 100, message: '长度在 2 到 100 个字符', trigger: 'blur' }
                    ],
                    phone: {required: true,validator: checkPhone, trigger: ['blur', 'change']},
                    ship_name:[
                        {required: true, message: '请输入联系人', trigger: 'blur'},
                        { min: 2, max: 10, message: '长度在2到10个字符', trigger: 'blur' }
                    ],
                    postcode:[
                        {validator: checkPostcode, trigger: ['blur']}
                    ],
                },
            }
        },
        mounted() {
            this.getAreaList()
            // if (this.$route.query.detail==='detail'){
                this.getDetail()
            // }
            console.log(this.$route.query);
            if (this.$route.query.onlineStatus == 1) {
                console.log(132);
                this.$router.push({
                    path: '/student/exam/onlineTradeManage/list',
                    query:{
                        exam_module_id:this.$route.query.exam_module_id,
                    }
                })
            }
        },
        methods:{
            //获取详情
            async getDetail(){
                // if (this.$route.query.detail==='detail'){
                    let param = {
                        detail:1
                    }
                    if (this.$route.query.op_id) {
                        param.op_id = this.$route.query.op_id;
                    }
                    if (this.$route.query.course_id) {
                        param.course_id = this.$route.query.course_id;
                    }
                    let res1 = await transactionGetArea();
                    this.provinceList = res1.data;

                    let res = await tranDistributionStuSalesAddressList(param);
                    this.addSparePartsForm.ship_name = res.data.ship_name
                    this.addSparePartsForm.phone = res.data.phone
                    this.addSparePartsForm.postcode = res.data.postcode
                    this.addSparePartsForm.detailed_address = res.data.detailed_address
                    let arr = res.data.where_address;
                    this.addSparePartsForm.province = arr[0];
                    this.addSparePartsForm.city = arr[1];
                    this.addSparePartsForm.area = arr[2];

                    this.provinceList.forEach(item => {
                        if (item.area_name === arr[0]) {
                            this.cityList = item.children_city;
                        }
                    });
                    this.cityList.forEach(item => {
                        if (item.area_name === arr[1]) {
                            this.areaList = item.children_region;
                        }
                    });
                    // tranDistributionStuSalesAddressList(param).then((res) => {
                    //     // this.infoList.push(res.data)
                    //     // this.infoList.where_address=res.data.where_address
                        // this.addSparePartsForm.ship_name = res.data.ship_name
                        // this.addSparePartsForm.phone = res.data.phone
                        // this.addSparePartsForm.postcode = res.data.postcode
                        // this.addSparePartsForm.detailed_address = res.data.detailed_address
                        // let arr = res.data.where_address.splice(" ");
                        // this.addSparePartsForm.province = arr[0];
                        // this.addSparePartsForm.city = arr[1];
                        // this.addSparePartsForm.area = arr[2];
                    // })
                // }
            },
            //获取地区列表
            async getAreaList() {
                let res = await transactionGetArea();
                this.provinceList = res.data;
                // transactionGetArea().then((res) => {
                //     this.provinceList = res.data;
                // }).catch((err)  => {
                //     console.log(err); 
                // })
            },
            //根据省选择市
            selectProvince(val) {
                if (val) {
                    this.provinceList.forEach(item => {
                        if (item.area_name === val) {
                            this.cityList = item.children_city;
                        }
                    })
                }
                this.addSparePartsForm.city = this.cityList[0].area_name
                this.selectCity(this.addSparePartsForm.city)
            },
            //根据市选择区
            selectCity(val){
                if (val) {
                    this.cityList.forEach(item => {
                        if (item.area_name === val) {
                            this.areaList = item.children_region;
                        }
                    })
                }
                this.addSparePartsForm.area = this.areaList[0].area_name
            },
            slelctArea(val) {
                if (val) {
                    this.addSparePartsForm.area = val;
                    this.getAreaList()
                }
            },
            //确定
            addressBtn(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let param ={}
                        let where_address = this.addSparePartsForm.province + " " + this.addSparePartsForm.city + " " + this.addSparePartsForm.area
                        param.where_address=where_address
                        param.detailed_address=this.addSparePartsForm.detailed_address
                        param.postcode=this.addSparePartsForm.postcode
                        param.phone=this.addSparePartsForm.phone
                        param.ship_name=this.addSparePartsForm.ship_name
                        if (this.$route.query.r_id){
                            obj.id=this.$route.query.r_id
                        }
                        if (this.$route.query.op_id){
                            param.op_id=this.$route.query.op_id
                        }
                        if (this.$route.query.course_id){
                            param.course_id=this.$route.query.course_id
                        }
                        if(this.addSparePartsForm.province==='' || this.addSparePartsForm.city===''||this.addSparePartsForm.area===''){
                            this.$message({type:'error', message:'所在地区不能为空'})
                            return ;
                        }
                        tranDistributionStuSalesAddress(param).then((res) => {
                            let onlineStatus = 1;
                            localStorage.setItem('onlineStatus', onlineStatus);
                            this.$message.success(res.msg)
                            if (this.$route.query.op_id){
                                this.$router.push({
                                    path: '/student/trainCenter/startTraining/list',
                                    query:{
                                        exam_module_id:this.$route.query.exam_module_id,
                                        op_id:this.$route.query.op_id,
                                        course_id:this.$route.query.course_id,
                                    }
                                })
                            }else {
                                this.$router.push({
                                    path: '/student/exam/onlineTradeManage/list',
                                    query:{
                                        exam_module_id:this.$route.query.exam_module_id,
                                    }
                                })
                            }
                        }).catch((err) => {
                            console.error('err', err);
                        });
                    } else {
                        return false;
                    }
                });
            },
            // 重置
            resetForm(){
                this.$refs.addSparePartsForm.resetFields();
                this.addSparePartsForm = this.$options.data().addSparePartsForm;
            },
            //返回
            resetBtn(){
                if (this.$route.query.op_id) {
                    this.$router.push({
                        path: '/student/trainCenter/startTraining/list',
                        query: {
                            op_id: this.$route.query.op_id,
                            detail: 'detail',
                            exam_module_id:this.$route.query.exam_module_id,
                            course_id:this.$route.query.course_id,
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/student/exam/onlineTradeManage/list',
                        query: {
                            detail: 'detail',
                            exam_module_id:this.$route.query.exam_module_id
                        }
                    })
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .spare-parts{
        display: flex;
        flex-direction: column;
        height: 100%;
        .addSpare-info {
            padding: 0 20px;
            /*height: calc(100vh - 220px);*/
            .addSpare-title {
                font-size: 20px;
                padding-left:16px;
                line-height: 1;
                margin: 20px 0 30px;
                display: flex;
                align-items: center;
            }
        }
        .dialog-footer {
            text-align: center;
            margin: 20px 0;
        }
        .where-address{
            &:before{
                content: "*";
                position: absolute;
                color: #E84932;
                left: 32px;
                margin-top: 12px;
            }
        }
        ::v-deep .el-form{
            height: 100%;
        }
    }

</style>